import { PropsWithChildren, useState } from 'react';

/** Props to the OpenAIKeyForm component. */
export interface OpenAIKeyFormProps {
  /** The current OpenAI API key. */
  currentKey: string;

  /** Callback to update the OpenAI API key. */
  setKey: (key: string) => void;

  /** If true, show the "Test" button. */
  showTestButton?: boolean;

  /** Extra class content. */
  className?: string;
}


/** A simple HTML form to allow updating an OpenAI API Key. */
export const OpenAIKeyForm: React.FC<PropsWithChildren<OpenAIKeyFormProps>> = ({ currentKey, setKey, children, className }) => {
  const [editingKey, setEditingKey] = useState<string>(currentKey);

  return (
    <form className={className}>
      <div className="flex space-x-4 items-center align-baseline">
        <label className="block flex-0 text-slate-500">OpenAI API Key</label>
        <input
          type="text"
          className="form-input px-2 py-1 flex-1 border rounded-md border-slate-200 text-slate-500 outline-none focus:border-slate-500 focus:text-slate-800"
          placeholder="sk-..."
          value={editingKey}
          onChange={(e) => setEditingKey(e.target.value)}
        />
        <button className="btn btn-primary rounded-md bg-emerald-500 px-2 py-1 hover:bg-emerald-700 text-white flex-initial"
          onClick={(e) => {
            e.preventDefault();
            setKey(editingKey);
            alert("Your API key has been saved!");
          }}
        >Save</button>
        {children}
      </div>
    </form>
  );
}
