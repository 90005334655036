import { Configuration, OpenAIApi } from 'openai';
import type { CreateCompletionRequest, CreateCompletionResponse } from "openai";
import { useLocalStorage } from "./useLocalStorage";
import { useCallback } from "react";

const PREFERRED_TEXT_MODEL = "text-davinci-002";
const PREFERRED_CODE_MODEL = "code-davinci-002";

export type CreateCompletionOptions = Omit<CreateCompletionRequest, "prompt">;

/** Return structure for useOpenAI hook. */
export interface OpenAIHook {
  /** The currently configured OpenAI API key. */
  apiKey: string;

  /** Method for updating the currently configured OpenAI API key. */
  setApiKey: (apiKey: string) => void;

  /** Low-level method for generating a completion and getting the primary result. */
  createCompletion: (
    request: CreateCompletionRequest
  ) => Promise<CreateCompletionResponse>;

  /** High-level method for generating a text completion. */
  completeText: (
    prompt: string,
    options?: CreateCompletionOptions
  ) => Promise<string | null>;

  /** High-level method for generating a code completion. */
  completeCode: (
    prompt: string,
    options?: CreateCompletionOptions
  ) => Promise<string | null>;
}

/** Provide full access to OpenAI in a react app. */
export const useOpenAI = (): OpenAIHook => {
  const [apiKey, setApiKey] = useLocalStorage<string>("openAIKey", "");

  const createCompletion = useCallback(
    async (request: CreateCompletionRequest) => {
      const config = new Configuration({ apiKey });
      const api = new OpenAIApi(config);
      const response = await api.createCompletion(request);
      return response.data;
    },
    [apiKey]
  );

  const completeText = useCallback(
    async (prompt: string, options?: CreateCompletionOptions) => {
      const response = await createCompletion({
        prompt,
        model: PREFERRED_TEXT_MODEL,
        ...options,
      });
      return response.choices?.[0].text?.trim() ?? null;
    },
    [createCompletion]
  );

  const completeCode = useCallback(
    async (prompt: string, options?: CreateCompletionOptions) => {
      const response = await createCompletion({
        prompt,
        model: PREFERRED_CODE_MODEL,
        ...options,
      });
      return response.choices?.[0].text?.trim() ?? null;
    },
    [createCompletion]
  );

  return {
    apiKey,
    setApiKey,
    createCompletion,
    completeText,
    completeCode,
  };
};
