import { OpenAIKeyForm } from "./OpenAIKeyForm";
import { useOpenAI } from "./useOpenAI";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";

// @ts-ignore
import Datatron from "./img/datatron.png";

const TEST_PROMPT =
  "Tell me that you're working. Please respond with a complete, light-hearted, delightful sentence.";

/** The root component of the app. */
export const App: React.FC = () => {
  const openAI = useOpenAI();
  const onDrop = useCallback(async (acceptedFiles: unknown) => {
    // Do something with the files
    console.log(acceptedFiles);




  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-12">
      <OpenAIKeyForm className="pb-8" currentKey={openAI.apiKey} setKey={openAI.setApiKey}>
        <button
          className="btn btn-primary rounded-md border border-slate-300 px-2 py-1 hover:bg-slate-300 text-slate-500 flex-initial"
          onClick={async (e) => {
            e.preventDefault();
            const completion = await openAI.completeText(TEST_PROMPT);
            alert(completion);
          }}
        >
          Test
        </button>
      </OpenAIKeyForm>

      <div className="flex items-baseline">
        <img src={Datatron} alt="datatron" className="w-24 h-24 rounded-full -ml-4" />
        <h1 className="text-3xl text-slate-900 font-bold mb-4">Hello. I'm Datatron 3000.</h1>
      </div>



      <p className="text-xl text-slate-800">I love getting down and nerdy with data. Give me your best CSVs!</p>


      <div {...getRootProps()} className="my-8 px-2 py-2 border-2 rounded-md border-slate-500">
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Here comes the plane...</p>
        ) : (
          <p>Feed me your best CSV files, right here!</p>
        )}
      </div>
    </div>
  );
};
